import {
  Button,
  Card,
  Center,
  Divider,
  Grid,
  Group,
  List,
  Stack,
  Text,
  Title,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useFetcher, useSubmit } from '@remix-run/react';
import { IconCheck } from '@tabler/icons-react';

import { Feature } from '@/types';
import { PLAN_FEATURES } from '@/utils/constants';

const showcasedFeatures = PLAN_FEATURES.filter((feature) => feature.isShowcased === true);

type PricingCardsProps = {
  allFeatures?: Feature[];
  bg?: string;
  breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  isUser?: boolean;
  isProPaid?: boolean;
};

export default function PricingCards({
  allFeatures = showcasedFeatures,
  bg,
  breakpoint = 'sm',
  isUser,
  isProPaid,
}: PricingCardsProps) {
  const fetcher = useFetcher();
  const theme = useMantineTheme();
  const submit = useSubmit();

  const freeFeatures = allFeatures
    .filter((feature) => feature.free !== false)
    .map((feature) => ({
      ...feature,
      description: typeof feature.free === 'string' ? feature.free : undefined,
    }));
  const basicFeatures = allFeatures
    .filter((feature) => feature.basic !== false)
    .map((feature) => ({
      ...feature,
      description: typeof feature.basic === 'string' ? feature.basic : undefined,
    }));

  const handleLogin = () => {
    notifications.show({
      message: 'Authenticating...',
    });
    submit(null, { action: '/auth', method: 'post' });
  };

  const handleUpgradeRequest = () => {
    fetcher.submit({ event: 'User ProBasicUpgradeRequested' }, { method: 'post' });

    notifications.show({
      title: 'Upgrade request received',
      message: 'DeepCast will get back to you shortly to help you upgrade.',
    });
  };

  return (
    <Stack gap="xl">
      <Grid gutter="md">
        <Grid.Col span={{ base: 12, [breakpoint]: 6 }}>
          <Card h="100%" c="white" bg={bg || theme.other.colors.deepDark}>
            <Stack gap="md">
              <Stack gap="xs">
                <Title order={2} size="h3">
                  Free
                </Title>
                <Text size="sm">For just starting out</Text>
              </Stack>
              <Stack>
                <Stack gap="xs">
                  <Group gap="xs">
                    <Text span size="xl" fw="600">
                      $0
                    </Text>
                    <Text span size="md">
                      /month
                    </Text>
                  </Group>
                  <Text size="sm">billed never</Text>
                </Stack>
                <Button onClick={handleLogin} disabled={!!isUser}>
                  {isUser ? 'Current plan' : 'Try for free'}
                </Button>
              </Stack>
              <Divider />
              <Title order={3} size="h6">
                Standout features
              </Title>
              <List spacing="sm" icon={<IconCheck style={{ width: rem(16), height: rem(16) }} />}>
                {freeFeatures.map((feature) => (
                  <List.Item key={feature.name}>
                    <Text size="xs">{feature.name}</Text>
                    <Text size="xs">{feature.description}</Text>
                  </List.Item>
                ))}
              </List>
            </Stack>
          </Card>
        </Grid.Col>
        <Grid.Col span={{ base: 12, [breakpoint]: 6 }}>
          <Card h="100%" c="white" bg={bg || theme.other.colors.deepDark}>
            <Stack gap="md">
              <Stack gap="xs">
                <Title order={2} size="h3" c={theme.other.brandColors.magenta}>
                  Basic
                </Title>
                <Text size="sm">For leveling up</Text>
              </Stack>
              <Stack>
                <Stack gap="xs">
                  <Group gap="xs">
                    <Text span size="xl" fw="600">
                      $22
                    </Text>
                    <Text span size="md">
                      /month
                    </Text>
                  </Group>
                  <Text size="sm">billed once monthly</Text>
                </Stack>
                {!isUser ? (
                  <Button onClick={handleLogin}>Try for free</Button>
                ) : (
                  <Button onClick={handleUpgradeRequest} color={theme.other.brandColors.magenta} disabled={isProPaid}>
                    Level up!
                  </Button>
                )}
              </Stack>
              <Divider />
              <Title order={3} size="h6">
                Standout features
              </Title>
              <List spacing="sm" icon={<IconCheck style={{ width: rem(16), height: rem(16) }} />}>
                {basicFeatures.map((feature) => (
                  <List.Item key={feature.name}>
                    <Text size="xs">{feature.name}</Text>
                    <Text size="xs">{feature.description}</Text>
                  </List.Item>
                ))}
              </List>
            </Stack>
          </Card>
        </Grid.Col>
      </Grid>
      <Center>
        <Button component="a" href="/pricing" variant="light" px={rem(24)}>
          Check out the full list of features
        </Button>
      </Center>
    </Stack>
  );
}
